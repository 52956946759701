export function extractErrors(response) {
  const errors = {
    fieldErrors: {},
    genericErrors: []
  };
  if (response && (response.status === 404 || response?.response?.status === 404)) {
    errors.genericErrors.push("The requested resource was not found.");
  }
  if (response && (response.status >= 500 || response?.response?.status >= 500)) {
    errors.genericErrors.push("Something went wrong. Please try again later.");
  }
  else if (response && response.name && response.name === "AxiosError" && (response.status !== 422 || response?.response?.status !== 422)) {
    errors.genericErrors.push(response.message);
  }
  if (response && response?.response?.status === 422) {
    const detail = response.response.data.detail;
    if (detail && detail.length > 0) {
      detail.forEach(error => {
        if (error.loc && error.loc.length > 0) {
          if (!errors.fieldErrors[error.loc[1]]) {
            errors.fieldErrors[error.loc[1]] = [];
          }
          errors.fieldErrors[error.loc[1]].push(error.msg);
        } else {
          errors.genericErrors.push(error.msg);
        }
      });
    }
  }
  else {
    if (response && response.data && response.data.errors) {
      response.data.errors.forEach(error => {
        if (error.field) {
          if (!errors.fieldErrors[error.field]) {
            errors.fieldErrors[error.field] = [];
          }
          errors.fieldErrors[error.field].push(error.message);
        } else {
          errors.genericErrors.push(error.message);
        }
      });
    }
  }

  return errors;
}
