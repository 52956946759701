import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUserProfile } from "./onboardingSlice";
import { loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";
import logo from "../../Logo.png"
import { getUserDetails, handleLogout } from "../UserAuthentication/AuthenticationSlice";

function OnboardingPage() {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const userType = useSelector((state) => state.auth.userType);
    const status = useSelector((state) => state.onboarding.status);
    const error = useSelector((state) => state.onboarding.error);
    const [baseSlide, setBaseSlide] = useState(1);
    const [slideCount, setSlideCount] = useState(1);
    const [businessAge, setBusinessAge] = useState("");
    const [industry, setIndustry] = useState("");
    const [businessState, setBusinessState] = useState("");
    const [revenue, setRevenue] = useState("");
    const [employeesCount, setEmployeesCount] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [organizationName, setOrganizationName] = useState("")
    const [phone, setPhone] = useState("");

    const [industryChanged, setIndustryChanged] = useState(false);

    const fieldToSlideMap = {
        first_name: 2,
        last_name: 2,
        phone_number: 2,
        organization_name: 2,
        industry: 3,
        state: 4,
        age_of_business: 5,
        num_employees: 6,
        revenue: 7
    };


    useEffect(() => {
        if (error && error.fieldErrors) {
            const fieldErrors = error.fieldErrors;
            let slides = [];
            console.log(fieldErrors)
            for (const [key, value] of Object.entries(fieldErrors)) {
                console.log(`${key}: ${fieldToSlideMap[key]}`)
                slides.push(fieldToSlideMap[key]);
            }
            if (slides.length > 0) {
                setSlideCount(Math.min(...slides));
            }
        }
        setIndustryChanged(false)
    }, [error]);

    useEffect(() => {
        if (status == "idle") {
            dispatch(getUserDetails());
        }
        else if (status === "success") {
            window.location.href = "/"
        }
    }, [status]);
    useEffect(() => {
        if (window.location.pathname === "/onboarding") {
            setBaseSlide(1);
        }
        else if (window.location.pathname === "/update-profile") {
            setBaseSlide(2);
            setSlideCount(2);
        }
    }, []);

    const handleSend = () => {
        var data = {
            "client_info": {
                "first_name": firstName,
                "last_name": lastName,
                "user_type": "user",
                "state": businessState,
                "industry": (industry),
                "age_of_business": businessAge,
                "num_employees": employeesCount,
                "annual_revenue": revenue,
                // "has_current_legal_issues": legalIssuesStatus === 'yes' ? true : false,
                "organization_name": organizationName,
                "phone_number": phone ? phone : -1,
                // "types_of_legal_help_required": legalHelp.join(',')
            }
        }
        dispatch(saveUserProfile(data));
        setSlideCount(8)
    };
    const signout = () => {
        dispatch(handleLogout())
    }

    useEffect(() => {
        if (userType === "lawyer") {
            navigate("/lawyer");
        }
    }, [userType]);
    useEffect(() => {
        if (user && user.first_name && !window.location.pathname.includes("update")) {
            navigate("/");
        }
        else if (user && user.first_name && window.location.pathname.includes("update")) {
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setBusinessState(user.state);
            setBusinessAge(user.age_of_business);
            setIndustry(user.industry);
            setOrganizationName(user.organization_name);
            if (user.phone_number !== -1) {
                setPhone(user.phone_number);
            }
            setEmployeesCount(user.num_employees);
            setRevenue(user.annual_revenue);
        }
    }, [user]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        dispatch(loadDataFromLocalStorage());
        dispatch(getUserDetails())
    }, []);
    return (
        <div className="App onboarding">
            <div className="container-fluid px-0">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <a className="navbar-brand me-auto" href="/">
                        <img src={logo} alt="Lexxa" className="logo-img" />
                    </a>
                    <div class="d-flex mx-5">
                        <button class="btn btn-sm btn-primary" onClick={signout}>Logout</button>
                    </div>
                </nav>

                <div className="row p-2 m-2 ">
                    <div class="col-12 col-md-8 offset-md-2 slides">
                        {slideCount !== 1 && slideCount !== 8 ?
                            <div class="progress mt-5 col-12 col-md-6 offset-md-3" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                <div class="progress-bar" style={{ width: `${(slideCount - 1) / 7 * 100}%` }}></div>
                            </div> : null}
                        {slideCount === 1 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">
                                    We’ll solve your legal problems and help guide you towards continued growth.
                                </h1>
                                <div class="text-center align-items-center">
                                    <button
                                        class="btn btn-outline-primary mt-5 btn-lg px-5"
                                        onClick={() => setSlideCount(slideCount + 1)}
                                    >
                                        Begin
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {slideCount === 2 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">What's Your Name?</h1>
                                <div class=" col-12 col-md-10 offset-md-1 mt-5">
                                    <div class="form-group mt-2">
                                        <label>First name</label>
                                        <input type="text" class="form-control col-12 col-md-6 mt-1" value={firstName} placeholder="First Name" aria-label="First Name"
                                            onChange={(e) => setFirstName(e.target.value)} />
                                        {(error && error.fieldErrors.first_name && firstName.length < 3) ? (
                                            <p class="text-danger">{error.fieldErrors.firstName}</p>
                                        ) : null}
                                    </div>
                                    <div class="form-group mt-4">
                                        <label >Last Name</label>
                                        <input type="text" class="form-control col-12 col-md-6 mt-1" value={lastName} placeholder="Last Name" aria-label="Last Name"
                                            onChange={(e) => setLastName(e.target.value)} />
                                        {(error && error.fieldErrors.last_name && lastName.length < 3) ? (
                                            <p class="text-danger">{error.fieldErrors.last_name}</p>
                                        ) : null}
                                    </div>
                                    <div class="form-group mt-4">
                                        <label>Organization Name</label>
                                        <input type="text" class="form-control col-12 col-md-6 mt-1" value={organizationName} placeholder="Organization Name" aria-label="Organization Name"
                                            onChange={(e) => setOrganizationName(e.target.value)} />
                                        {(error && error.fieldErrors.organization_name && organizationName.length < 3) ? (
                                            <p class="text-danger">{error.fieldErrors.organization_name}</p>
                                        ) : null}
                                    </div>
                                    <div class="form-group mt-4">
                                        <label>Phone (optional)</label>
                                        <input type="text" class="form-control col-12 col-md-6 mt-1" value={phone} placeholder="Phone Number (1234567890)" aria-label="Phone Number"
                                            onChange={(e) => setPhone(e.target.value)} />
                                        {(error && error.fieldErrors.phone) ? (
                                            <p class="text-danger">{error.fieldErrors.phone}</p>
                                        ) : null}
                                    </div>
                                </div>

                                <div class="col-12 align-items-center text-center mt-5">
                                    <button class="btn btn-lg btn-outline-primary" disabled={!(firstName.length > 2 && lastName.length > 2 && organizationName.length > 2)}
                                        onClick={() => setSlideCount(slideCount + 1)}
                                    >Next</button>
                                </div>
                            </div>
                        ) : null}
                        {slideCount === 3 ? (
                            <div className="hero p-5">

                                <h1 className="text-center">What Industry is your business (<i>{organizationName}</i>) in?</h1>
                                <div class="text-center align-items-center col-12 col-md-8 offset-md-2">
                                    <select
                                        class="col-12 form-select-lg mt-3"
                                        name="industry"
                                        value={industry}
                                        onChange={(e) => { setIndustry(e.target.value); setIndustryChanged(true) }}
                                    >
                                        <option value='' selected>Select Industry</option>
                                        <option value="Consulting">Consulting</option>
                                        <option value="Construction">Construction</option>
                                        <option value="E-Commerce">E-Commerce</option>
                                        <option value="Education">Education</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Franchise">Franchise</option>
                                        <option value="Food/Beverage">Food/Beverage</option>
                                        <option value="Healthcare/Medical">Healthcare/Medical</option>
                                        <option value="Hospitality">Hospitality</option>
                                        <option value="Insurance">Insurance</option>
                                        <option value="Non-Profit">Non-Profit</option>
                                        <option value="Professional Services">Professional Services</option>
                                        <option value="Real Estate">Real Estate</option>
                                        <option value="Start-Up">Start-Up</option>
                                        <option value="Technology">Technology</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {(error && error.fieldErrors.industry && industryChanged === false) ? (
                                        <p class="text-danger">{error.fieldErrors.industry}</p>
                                    ) : null}
                                    <button
                                        class="btn btn-outline-primary mt-5 btn-lg px-5"
                                        onClick={() => setSlideCount(slideCount + 1)}
                                        disabled={industry === ""}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {slideCount === 4 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">What state is <i>{organizationName}</i> primarily located in?</h1>
                                <div class="text-center align-items-center col-12 col-md-8 offset-md-2">
                                    <select
                                        class="col-12 form-select-lg mt-3"
                                        name="state"
                                        value={businessState}
                                        onChange={(e) => setBusinessState(e.target.value)}
                                    >
                                        <option value='' selected>Select State</option>
                                        <option value="Alabama">Alabama</option>
                                        <option value="Alaska">Alaska</option>
                                        <option value="Arizona">Arizona</option>
                                        <option value="Arkansas">Arkansas</option>
                                        <option value="California">California</option>
                                        <option value="Colorado">Colorado</option>
                                        <option value="Connecticut">Connecticut</option>
                                        <option value="Delaware">Delaware</option>
                                        <option value="Florida">Florida</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Hawaii">Hawaii</option>
                                        <option value="Idaho">Idaho</option>
                                        <option value="Illinois">Illinois</option>
                                        <option value="Indiana">Indiana</option>
                                        <option value="Iowa">Iowa</option>
                                        <option value="Kansas">Kansas</option>
                                        <option value="Kentucky">Kentucky</option>
                                        <option value="Louisiana">Louisiana</option>
                                        <option value="Maine">Maine</option>
                                        <option value="Maryland">Maryland</option>
                                        <option value="Massachusetts">Massachusetts</option>
                                        <option value="Michigan">Michigan</option>
                                        <option value="Minnesota">Minnesota</option>
                                        <option value="Mississippi">Mississippi</option>
                                        <option value="Missouri">Missouri</option>
                                        <option value="Montana">Montana</option>
                                        <option value="Nebraska">Nebraska</option>
                                        <option value="Nevada">Nevada</option>
                                        <option value="New Hampshire">New Hampshire</option>
                                        <option value="New Jersey">New Jersey</option>
                                        <option value="New Mexico">New Mexico</option>
                                        <option value="New York">New York</option>
                                        <option value="North Carolina">North Carolina</option>
                                        <option value="North Dakota">North Dakota</option>
                                        <option value="Ohio">Ohio</option>
                                        <option value="Oklahoma">Oklahoma</option>
                                        <option value="Oregon">Oregon</option>
                                        <option value="Pennsylvania">Pennsylvania</option>
                                        <option value="Rhode Island">Rhode Island</option>
                                        <option value="South Carolina">South Carolina</option>
                                        <option value="South Dakota">South Dakota</option>
                                        <option value="Tennessee">Tennessee</option>
                                        <option value="Texas">Texas</option>
                                        <option value="Utah">Utah</option>
                                        <option value="Vermont">Vermont</option>
                                        <option value="Virginia">Virginia</option>
                                        <option value="Washington">Washington</option>
                                        <option value="West Virginia">West Virginia</option>
                                        <option value="Wisconsin">Wisconsin</option>
                                        <option value="Wyoming">Wyoming</option>
                                    </select>
                                    {(error && error.fieldErrors.state && businessState === "") ? (
                                        <p class="text-danger">{error.fieldErrors.state}</p>
                                    ) : null}
                                    <button
                                        class="btn btn-outline-primary mt-5 btn-lg px-5"
                                        onClick={() => setSlideCount(slideCount + 1)}
                                        disabled={businessState === ""}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {slideCount === 5 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">How long have you been running <i>{organizationName}</i>?</h1>
                                <div class="text-center align-items-center col-12 col-md-10 offset-md-1 mt-5">
                                    <button onClick={() => setBusinessAge("Not Started")}
                                        className={businessAge === "Not Started" ? "btn btn-primary btn-round mt-2" : "btn btn-outline-primary btn-round mt-2"}>
                                        Not Started
                                    </button>
                                    <button onClick={() => setBusinessAge("0-2 Years")}
                                        className={businessAge === "0-2 Years" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        0-2 Years
                                    </button>
                                    <button onClick={() => setBusinessAge('2-10 Years')}
                                        className={businessAge === '2-10 Years' ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        2-10 Years
                                    </button>
                                    <button
                                        onClick={() => setBusinessAge('Over 10 Years')}
                                        className={businessAge === 'Over 10 Years' ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}
                                    >
                                        Over 10 Years
                                    </button>
                                    {(error && error.fieldErrors.age_of_business && businessAge === "") ? (
                                        <p class="text-danger">{error.fieldErrors.age_of_business}</p>
                                    ) : null}
                                    <div class="col-12">
                                        <button
                                            class="btn btn-outline-primary mt-5 btn-lg px-5"
                                            onClick={() => setSlideCount(slideCount + 1)}
                                            disabled={businessAge === ""}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {slideCount === 6 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">How many employees does <i>{organizationName}</i> has?</h1>
                                <div class="text-center align-items-center col-12 col-md-10 offset-md-1 mt-5">
                                    <button onClick={() => setEmployeesCount("0")}
                                        className={employeesCount === "0" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        0
                                    </button>
                                    <button onClick={() => setEmployeesCount("1-20")}
                                        className={(employeesCount === "1-20") ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        1 - 20
                                    </button>
                                    <button onClick={() => setEmployeesCount("21-100")}
                                        className={(employeesCount === "21-100") ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        20 - 100
                                    </button>
                                    <button onClick={() => setEmployeesCount('101-1000')}
                                        className={(employeesCount === '101-1000') ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        100 - 1000
                                    </button>
                                    <button onClick={() => setEmployeesCount('1000+')}
                                        className={(employeesCount === '1000+') ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}>
                                        1000+
                                    </button>

                                </div>
                                {(error && error.fieldErrors.num_employees && employeesCount === "") ? (
                                    <p class="text-danger text-center">{error.fieldErrors.num_employees}</p>
                                ) : null}
                                <div class="col-12 align-items-center text-center">
                                    <button
                                        class="btn btn-outline-primary mt-5 btn-lg px-5"
                                        onClick={() => setSlideCount(slideCount + 1)}
                                        disabled={employeesCount === ""}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        ) : null}
                        {slideCount === 7 ? (
                            <div className="hero p-5">
                                <h1 className="text-center">What's the annual revenue of {organizationName}?</h1>
                                <div class="text-center align-items-center col-12 col-md-10 offset-md-1 mt-5">
                                    <button
                                        onClick={() => setRevenue("less than 250k")}
                                        className={revenue === "less than 250k" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}
                                    >
                                        Less than $250,000
                                    </button>
                                    <button
                                        onClick={() => setRevenue("250k to 1m")}
                                        className={revenue === "250k to 1m" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}
                                    >
                                        $250,000 to $1 Million
                                    </button>
                                    <button
                                        onClick={() => setRevenue("1m to 10m")}
                                        className={revenue === "1m to 10m" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}
                                    >
                                        $1 Million to $10 Million
                                    </button>
                                    <button
                                        onClick={() => setRevenue("10m+")}
                                        className={revenue === "10m+" ? "btn btn-primary btn-round ms-2 mt-2" : "btn btn-outline-primary btn-round ms-2 mt-2"}
                                    >
                                        Over $10 Million
                                    </button>
                                </div>
                                {(error && error.fieldErrors.annual_revenue && revenue === "") ? (
                                    <p class="text-danger text-center">{error.fieldErrors.annual_revenue}</p>
                                ) : null}
                                <div class="col-12 align-items-center text-center">
                                    <button
                                        class="btn btn-outline-primary mt-5 btn-lg px-5"
                                        onClick={handleSend}
                                        disabled={revenue === ""}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        ) : null}
                        {slideCount === 8 ? (
                            <div className="hero p-5">
                                {status === "loading" ? <div class="col-12 align-items-center text-center">
                                    <p>
                                        <span class="spinner-grow spinner-grow-lg text-primary mt-5" role="status"></span>
                                        <span class="ms-3">Saving your profile</span></p> </div> : null}
                                {status === "success" ?
                                    <>
                                        {baseSlide !== 2 ?
                                            <>
                                                <h1 class="text-center">Welcome to the Lexxa Pilot!</h1>
                                                <h2 class="mt-5">Here's how this works:</h2>

                                                <ol>
                                                    <li> <i class="bi bi-pencil"></i> Ask Lexxa for any legal task, such as:
                                                        <ol class="alpha-label">
                                                            <li>Draft an independent contractor agreement</li>
                                                            <li>Review this NDA and call out anything unusual</li>
                                                            <li>Advise me on what to do before hiring our first employee</li>
                                                        </ol>
                                                    </li>
                                                    <li> <i class="bi bi-emoji-sunglasses"></i>  Click "Get Legal Review" if you want Lexxa's work-product checked by a real lawyer who will respond within 2 business days.</li>
                                                    <li>Open a new chat for each legal issue to stay organized</li>
                                                </ol>

                                                <div class="text-center align-items-center">
                                                    <button class="btn btn-outline-primary " onClick={() => {
                                                        navigate(`/`);
                                                    }}>Start Chat</button>
                                                </div>
                                            </> : null}

                                    </> : null
                                }
                                {(status === "failed" && Object.keys(error.fieldErrors).length === 0) ? (
                                    <>
                                        {error.genericErrors.length > 0 ? (
                                            <div class="alert alert-danger" role="alert">
                                                {error.genericErrors.map((error) => (
                                                    <p>{error}</p>
                                                ))}
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
                {slideCount !== baseSlide ? (
                    <div class="offset-2">
                        <button class="btn btn-outline-primary m-5 offset-3" onClick={() => setSlideCount(slideCount - 1)}>
                            Back
                        </button>
                    </div>
                ) : null}
            </div>
        </div >
    );
}

export default OnboardingPage;
