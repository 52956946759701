import React, { useCallback, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "../UserAuthentication/AuthenticationSlice";
import {
  MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { handleLogout } from "../UserAuthentication/AuthenticationSlice";
import { marked } from "marked";
import {
  sendMessage,
  enableNewChat,
  disableNewChat,
  setThreadId,
  getThread,
  getAllThreads,
  resetChatPage,
  deleteThread,
  updateViewedChats,
  requestLegalReview,
  getTitle,
  sendFeedback,
  sendFeedbackWithMessage,
  updateTitle,
  addFeedbackToMessage
} from "./chatSlice";
import { useNavigate } from "react-router-dom";
import { loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";

function ChatPage() {
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [action, setAction] = useState("");
  const [messageId, setMessageId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [copiedItem, setCopiedItem] = useState("");
  const [userImageMapping, setUserImageMapping] = useState({});
  const [userImage, setUserImage] = useState();
  const [feedback, setFeedback] = useState("");
  const [legalReviewDisabled, setLegalReviewDisabled] = useState(false);
  const [legalReviewAvailable, setLegalReviewAvailable] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [basicModal3, setBasicModal3] = useState(false);

  const dispatch = useDispatch();
  // const images = require("../../images/", true);
  const messages = useSelector((state) => state.chat.messages);
  const status = useSelector((state) => state.chat.status);
  const threads = useSelector((state) => state.chat.threads);
  const threadId = useSelector((state) => state.chat.threadId);
  const getAllThreadError = useSelector((state) => state.chat.getAllThreadError);
  const getAllThreadStatus = useSelector((state) => state.chat.getAllThreadStatus);
  const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
  const getThreadError = useSelector((state) => state.chat.getThreadError);
  const user = useSelector((state) => state.auth.user);
  const userType = useSelector((state) => state.auth.userType);
  const lawyerDetails = useSelector((state) => state.auth.lawyerDetails);
  const messagingDisabled = useSelector((state) => state.chat.messagingDisabled);
  const notUpdatedThreads = useSelector((state) => state.chat.notUpdatedThreads);
  const infoMissing = useSelector((state) => state.auth.infoMissing);
  const viewedChats = useSelector((state) => state.chat.viewedChats);
  const legalReviewRequested = useSelector((state) => state.chat.legalReviewRequested);
  const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
  const groupedthreads = useSelector((state) => state.chat.threadsNewStructure);
  const updateTitleStatus = useSelector((state) => state.chat.updateTitleStatus);
  const updateTitleError = useSelector((state) => state.chat.updateTitleError);
  const feedbackStatus = useSelector((state) => state.chat.feedbackStatus);
  const deleteThreadStatus = useSelector((state) => state.chat.deleteThreadStatus);

  let { chatId } = useParams();
  const location = useLocation();


  function setCopiedItemFunction(message_id) {
    setCopiedItem(message_id);
    setTimeout(function () {
      setCopiedItem("");
    }, 5000);
  }


  useEffect(() => {
    if (status === "idle") {
      setMessage("");
      if (user && user.user_id && userType == "client") {
        dispatch(getAllThreads({ userId: user.user_id }));
        if (user.first_name) {
          setUserImage(require(`../../images/white/${user.first_name[0].toUpperCase()}.png`));
        }
      }
      else if (user && userType == "lawyer") {
        window.location.href = "/lawyer";
      }
    }
    if (status == "failed") {
      setErrorMessage("Failed to send message. Please refresh the page and try again.");
    }
  }, [status, user]);

  // get the thread details when the link change. this will be used to get the thread details when the user clicks on a thread
  useEffect(() => {
    if (location.pathname !== "/") {
      dispatch(getThread(chatId));
      dispatch(updateViewedChats(chatId));
      setNewTitle("");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (deleteThreadStatus === "success") {
      dispatch(getAllThreads({ userId: user?.user_id }))
    }
  }, [deleteThreadStatus]);

  if (chatId == undefined) {
    chatId = null;
    dispatch(enableNewChat());
  } else {
    dispatch(disableNewChat());
    dispatch(setThreadId(chatId));
  }

  // actions to be taken when the user opts to create new thread.
  const handleNewChatClick = () => {
    dispatch(disableNewChat());
    dispatch(setThreadId(null));
    setMessage("");
    setThreadId(null);
    dispatch(resetChatPage());
    navigate("/", { state: {} });
    setLegalReviewDisabled(false)
  };

  // Request legal review for the given thread
  const requestReview = () => {
    dispatch(requestLegalReview({ threadId: threadId }));
  }
  // send message / create new thread
  const handleSend = () => {
    dispatch(sendMessage({ message_text: message, thread_id: threadId }));
  };

  // delete given thread
  const deleteGivenThread = (threadId) => {
    dispatch(deleteThread({ threadId: threadId }));
  };
  const updateThreadTitle = () => {
    dispatch(updateTitle({ thread_id: chatId, title: newTitle }));
  }

  const signout = () => {
    dispatch(handleLogout())
  }
  const updateFeedback = () => {
    dispatch(sendFeedbackWithMessage({ feedback_type: action, message_id: messageId, feedback: feedback }));
  }
  useEffect(() => {
    if (feedbackStatus == "success") {
      toggleOpen();
      alert("Your feedback has been recorded. Thank you for your feedback.")
    }
  }, [feedbackStatus])

  useEffect(() => {
    if (updateTitleStatus == "success") {
      setNewTitle("");
      toggleOpen3();
      dispatch(getAllThreads({ userId: user?.user_id }));
    }
  }, [updateTitleStatus]);

  // if the user info is missing, this will fetch it
  useEffect(() => {
    if (infoMissing == true) {
      dispatch(getUserDetails())
    }
  }, [infoMissing]);

  // this will generate the ai title of the threads for which the title is not generated by ai
  useEffect(() => {
    if (notUpdatedThreads.length > 0) {
      notUpdatedThreads.map((thread) => {
        dispatch(getTitle(thread));
      });
    }
  }, [notUpdatedThreads]);

  // if the user type is lawyer, take the lawyer to lawyer page, and not keep them on the client page
  useEffect(() => {
    if (chatId == null) {
      if (userType == "lawyer") {
        navigate("/lawyer");
      }
    }
  }, [userType]);

  // if there is no chat id selected, and the user's first name is available, take the user to /
  useEffect(() => {
    if (chatId == null && window.location.pathname !== "/") {
      if (user && user.first_name) {
        navigate("/");
      }
    }
    if (user && (user.first_name === "" || user.first_name === null || user.first_name === undefined)) {
      navigate("/onboarding");
    }
  }, [user]);

  // generate the image mapping for the users. this will be used in getting the icons for the users
  useEffect(() => {
    var userImageMapping = {}
    userImageMapping['lexxa'] = require(`../../images/gradient/LEXXA.png`);
    if (messages.length > 0) {
      messages.forEach(function (message) {
        if (message.user != null) {
          if (message.user.user_type == "lawyer") {
            userImageMapping[message.user.user_id] = require(`../../images/lawyer.png`);
          }
          else {
            userImageMapping[message.user.user_id] = require(`../../images/white/${message.user.first_name[0].toUpperCase()}.png`);
          }
        }
      });
      // userImageMapping['lexxa'] = require(`../../images/gradient/LEXXA.png`);
      var mydiv = document.getElementsByClassName("chat-content-area")[0]
      mydiv.scrollTo({ top: mydiv.scrollHeight, behavior: "smooth" });
    }
    setUserImageMapping(userImageMapping);
  }, [messages]);


  function setActionName(actionName, message_id) {
    setAction(actionName);
    setMessageId(message_id);
    toggleOpen();
    setFeedback("")
    dispatch(sendFeedback({ feedback_type: actionName, message_id: message_id, feedback: "" }));
    dispatch(addFeedbackToMessage({ "message_id": message_id, "action": actionName }));
  }
  const toggleOpen = () => setBasicModal(!basicModal);
  const toggleOpen2 = () => setBasicModal2(!basicModal2);
  const toggleOpen3 = () => setBasicModal3(!basicModal3);

  // set legal review enabled/ disabled, based on the thread details
  useEffect(() => {
    setLegalReviewAvailable(false)
    setLegalReviewDisabled(false);
    var currentThread = threads.find((thread) => thread.thread_id == chatId);
    if (legalReviewRequested) {
      setLegalReviewDisabled(true);
    }
    else {
      if (currentThread != undefined && currentThread.legal_review_submitted) {
        setLegalReviewAvailable(true)
        setLegalReviewDisabled(true);
      }
      else if (currentThread != undefined && currentThread.legal_review_requested) {
        setLegalReviewDisabled(true);
      }
      else {
        if (requestLegalReviewStatus == "loading" || messagingDisabled) {
          setLegalReviewDisabled(true);
        }
        setLegalReviewDisabled(false);
      }
    }
    if (currentThread?.legal_review_submitted) {
      setLegalReviewDisabled(true);
    }
  }, [legalReviewRequested, threads, chatId]);


  const convertToHtml = (text) => {
    return marked(text.replace(/\n/g, '<br>'));
  }
  useEffect(() => {
    if (requestLegalReviewStatus === "success") {
      dispatch(getAllThreads({ userId: user?.user_id }));
    }
  }, [requestLegalReviewStatus])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(loadDataFromLocalStorage());
    dispatch(getUserDetails());

  }, []);
  return (
    <div className="App">
      <MDBModal open={basicModal} setopen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-dark">
                <i
                  className={action == "like" ? "bi bi-hand-thumbs-up p-1 ms-2 pe-3 " : "bi bi-hand-thumbs-down p-1 ms-2 pe-3 "}
                  data-toggle="modal"
                  data-target="#exampleModal"
                ></i>
                Provide Additional Feedback</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p class="text-dark">Your review has been recorded. Please provide additional details</p>
              <div>
                <textarea
                  name=""
                  id=""
                  class="col-12 p-1 form-control"
                  placeholder={action === "like" ? "What do you like about the response?" : "What did you not like about the response?"}
                  value={feedback}
                  onChange={(e) => {
                    setFeedback(e.target.value);
                    setErrorMessage("");
                  }}
                  disabled={status === "loading"}
                ></textarea>
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <button class="btn btn-secondary" onClick={toggleOpen}>
                Close
              </button>
              <button class="btn btn-primary" disabled={feedbackStatus === "loading" || feedback.length < 20}
                onClick={updateFeedback}
              >Send Feedback</button>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={basicModal2} setopen={setBasicModal2} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-dark">Profile details</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen2}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div class="row">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">First Name</th>
                      <td scope="col">{user?.first_name}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Last Name</th>
                      <td>{user?.last_name}</td>
                    </tr>
                    <tr>
                      <th>Organization</th>
                      <td>{user?.organization_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen2}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal open={basicModal3} setopen={setBasicModal3} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle className="text-dark">Update Chat Title</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen3}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <div>
                <label className="text-dark font-weight-bold">Add New Chat Title</label>
                <textarea
                  name=""
                  id=""
                  class="col-12 p-1 form-control"
                  placeholder="Please add new title for the chat"
                  value={newTitle}
                  onChange={(e) => {
                    setNewTitle(e.target.value);
                  }}
                  disabled={updateTitleStatus === "loading"}
                ></textarea>
                <button class="btn btn-dark mt-2" onClick={updateThreadTitle}>Update Title</button>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <section className="bannerContainer">
        <div class="container-fluid">
          <div class="row">
            <div class="side-nav col-lg-3 col-md-12 pt-2">
              <div class="side-nav-threads">
                <div class="row p-2">
                  <div class="col-12 px-3">
                    <button class="btn col-12 new-chat-btn py-2" onClick={handleNewChatClick}>
                      New Legal Question
                      <span class="pull-end float-end">
                        <i class="bi bi-pencil-square"></i>
                      </span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="p-3">
                    {(getAllThreadStatus == "failed" && Object.keys(getAllThreadError.fieldErrors).length === 0) ? (
                      <>
                        {getAllThreadError.genericErrors.length > 0 ? (
                          <>
                            {getAllThreadError.genericErrors.map((error) => (
                              <p class="text-danger">Failed to load history. Please try again later.</p>
                            ))}
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {getAllThreadStatus == "loading" ? (
                      <div class="chat-content-area">
                        <div class="row">
                          <div class="col-md-2 offset-md-4 mt-5">
                            <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                            </div>
                          </div>
                        </div>
                      </div>) : null}

                    {Object.entries(groupedthreads).map(([key, threadGroup]) => (
                      <>
                        <div class="row p-3 pb-0">
                          <p key={key} className="text-secondary px-2 py-0 my-0">{key}</p>
                        </div>
                        <ul class="my-0">

                          {threadGroup.map((thread, index) => (
                            <li key={index} className={thread.thread_id == chatId ? "row thread bg-secondary text-white" : "row thread"}>
                              <div className={thread.thread_id == chatId ? "chat-list-item col-10" : "chat-list-item col-12"} onClick={() => {
                                navigate(`/c/${thread.thread_id}`);
                              }}>
                                <p class="mb-2">
                                  {(thread.legal_review_requested && thread.legal_review_submitted && (thread.legal_review_viewed || viewedChats.indexOf(thread.thread_id) !== -1)) ? (
                                    <i class="bi bi-check-lg me-2 text-success"></i>
                                  ) : null}
                                  {(thread.legal_review_requested && thread.legal_review_submitted && !thread.legal_review_viewed && viewedChats.indexOf(thread.thread_id) === -1) ? (
                                    <i class="bi bi-circle-fill me-2 text-danger"></i>
                                  ) : null}
                                  {(thread.legal_review_requested && !thread.legal_review_submitted) ? (
                                    <i class="bi bi-circle-fill me-2 text-warning"></i>
                                  ) : null}

                                  {thread.title}</p>
                              </div>
                              {thread.thread_id == chatId ?
                                <div class="col-2">
                                  <MDBDropdown>
                                    <MDBDropdownToggle tag='p'>
                                      <i class="bi bi-three-dots"></i>
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                      <MDBDropdownItem link onClick={toggleOpen3}>Update Chat Title</MDBDropdownItem>
                                      <MDBDropdownItem link onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this thread?")) {
                                          deleteGivenThread(thread.thread_id);
                                        }
                                      }}>Delete Chat</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                  </MDBDropdown>

                                </div>
                                : null}
                            </li>
                          ))}
                        </ul>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              <div class="row side-nav-footer py-2">
                <div class="col-12 px-4">
                  <MDBDropdown>
                    <MDBDropdownToggle tag='p'>
                      <img src={userImage} class="chatgpt-icon ms-3 me-2" /> {user?.first_name}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <MDBDropdownItem link onClick={toggleOpen2}>View Profile</MDBDropdownItem>
                      <MDBDropdownItem link onClick={() => { navigate("/update-profile") }}>Update Profile</MDBDropdownItem>
                      <MDBDropdownItem link onClick={signout}>Logout</MDBDropdownItem>
                      {/* <MDBDropdownItem link>Something else here</MDBDropdownItem> */}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            </div>

            <div class="content  p-0 pt-2 col-lg-9 col-md-12">
              {getThreadStatus != "loading" ?
                <div class="chat-content-area">
                  {(messages.length == 0 && status == "idle") ? (
                    <div class="row gpt-chat-box parent-div">
                      <div class="chat-icon col-1 text-center">

                        <img class="chatgpt-icon" src={userImageMapping['lexxa']} />

                      </div>
                      <div class="chat-txt col-10">
                        <p class="text-larger">
                          <b>Lexxa</b>
                        </p>
                        <p>Hi {user?.first_name}! What legal work do you need today?</p>

                      </div>
                    </div>
                  ) : null
                  }
                  {messages.map((message, index) => (
                    <div key={index} className={index % 2 === 0 ? "row gpt-chat-box parent-div" : "row user-chat-box parent-div"}>
                      <div class="chat-icon col-1 text-center">
                        {message.user == null ? (
                          <img class="chatgpt-icon" src={userImageMapping['lexxa']} />)
                          : <img class="chatgpt-icon" src={userImageMapping[message.user?.user_id]} />
                        }
                      </div>
                      <div class="chat-txt col-10">
                        <p class="text-larger">
                          <b>{message.user == null ? "Lexxa" : <>
                            {message.user?.user_id == user?.user_id ? <>You</> : <>{message.user?.first_name}</>}
                            {message.user && message.user.user_type == "lawyer" ? <> (Legal Advisor)</> : null}
                          </>}
                          </b>
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: convertToHtml(message.text) }}></div>
                        {message.user?.user_id != user.user_id ? (
                          <p class="show-hide-text mt-1 fs-small ">

                            {copiedItem == message.id ? (
                              <i class="bi bi-check p-1 "></i>
                            ) : (
                              <i
                                class="bi bi-clipboard p-1 "
                                onClick={() => {
                                  navigator.clipboard.writeText(message.text);
                                  setCopiedItemFunction(message.id);
                                }}
                              ></i>
                            )}
                            {!message?.feedback_type ? (<>
                              <i
                                class="bi bi-hand-thumbs-up p-1 ms-2 ps-3 "
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setActionName("like", message.id)}
                              ></i>
                              <i
                                class="bi bi-hand-thumbs-down p-1 ms-2 ps-3 "
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() => setActionName("dislike", message.id)}
                              ></i>
                            </>) :
                              <i
                                className={message?.feedback_type === "like" ? "bi bi-hand-thumbs-up-fill p-1 ms-2 ps-3" : "bi bi-hand-thumbs-down-fill p-1 ms-2 ps-3"}
                              ></i>}
                            {(message.user?.user_type != "lawyer") ? (
                              <>
                                {(!legalReviewDisabled) ?
                                  <button class="btn btn-outline-dark btn-sm ms-3" onClick={() => {
                                    if (window.confirm("You will not be able to send more messages once the request is submitted. The legal advisor will get back to you in 2 business days")) {
                                      requestReview()
                                    }
                                  }}
                                  >Request legal review</button>
                                  : <button class="btn btn-dark btn-sm ms-3" disabled >Legal review requested</button>
                                }</>) : null}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
                : <div class="chat-content-area">
                  <div class="row">
                    <div class="col-md-2 offset-md-5 mt-5">
                      <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                      </div>
                    </div>
                  </div>
                </div>}

              <div class="chat-input-area overflow-hidden">
                <div class="row">
                  {!legalReviewDisabled ?
                    <div class=" col-12  pt-0  mt-0 chat-inputs-area-inner">
                      <span style={{ visibility: status !== "loading" ? "hidden" : "visible" }}>
                        <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        {"   "} Please wait up to 30 seconds while we analyze your request.
                      </span>
                      <div class="row chat-inputs-container mt-2 align-items-center">
                        <textarea
                          name=""
                          id=""
                          class="col-11 pb-0"
                          placeholder="Message Lexxa"
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                            setErrorMessage("");
                          }}
                          disabled={status === "loading"}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey && (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)) {
                              handleSend();
                            }
                          }}
                        ></textarea>

                        <span class="col-1">
                          <button class="btn btn-outline-dark btn-sm" onClick={handleSend} disabled={status === "loading"}>
                            <i class="bi bi-arrow-up cursor" style={{ fontSize: "20px" }} aria-hidden="true" ></i>
                          </button>
                        </span>
                      </div>
                      <p class="text-muted text-center">Consult your lawyer before relying on Lexxa</p>
                      {(getThreadStatus == "failed" && Object.keys(getThreadError.fieldErrors).length === 0 && message.length == 0) ? (
                        <>
                          {/* <p class="text-danger">Failed to load history. Please try again later.</p> */}
                          {getThreadError.genericErrors.length > 0 ? (
                            <>
                              {getThreadError.genericErrors.map((error) => (
                                <p class="text-danger">Unable to fetch messages in the thread. Reason: {error}</p>
                              ))}
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {errorMessage === "" ? null : <p class="text-danger">{errorMessage}</p>}
                    </div>
                    : <div class=" col-12  pt-0 chat-inputs-area-inner">
                      {
                        (!legalReviewAvailable) ?
                          <p class="text-muted text-center pt-5">You can no longer add messages to this chat. Your lawyer will reach out to you within 2 business days.</p>

                          : <p class="text-muted text-center pt-5">The conversation has ended. You can find the lawyer’s response above. Start another chat for new queries.</p>
                      }</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </div >
  );
}

export default ChatPage;
