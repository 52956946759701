// import logo from './logo.svg';
import './App.css';
import React, { useEffect, useReducer, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RequireAuth } from "./persistence/middlewares/AuthMiddleware";
import ChatPage from "./pages/chatpage/ChatComponent";
import OnboardingPage from "./pages/onboarding/OnboardingComponent";
import { Provider } from "react-redux";
import RootStore from "./persistence/stores/RootStore";
import Signin from './pages/UserAuthentication/Signin';
// import Signup from './pages/UserAuthentication/SignupComponent';
import { auth } from './firebase';
import LawyerPage from './pages/chatpage/lawyerComponent';
// import { getUserDetails, loadDataFromLocalStorage } from './pages/UserAuthentication/AuthenticationSlice';

export const AuthContext = React.createContext();


function App() {
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          let token;
          if (action.token === "false") {
            token = null;
          } else token = action.token;

          return {
            ...prevState,
            userToken: token,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
          };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
    }
  );

  const authContext = useMemo(
    () => ({
      signIn: async (data) => {
        localStorage.setItem("token", data);
        dispatch({ type: "SIGN_IN", token: data });
      },
      signOut: async () => {
        localStorage.setItem("token", "false");
        window.localStorage.clear();
        dispatch({ type: "SIGN_OUT" });
      },
    }),
    []
  );

  const updateAuthTokenWithoutRefresh = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then((token) => {
          localStorage.setItem("accessToken", token);
        });
      }
    })
    setTimeout(updateAuthTokenWithoutRefresh, 1 * 10 * 1000);
  }

  const updateAuthToken = auth.onAuthStateChanged((user) => {
    if (user) {
      user.getIdToken().then((token) => {
        if (token != localStorage.getItem("accessToken")) {
          if (window.location.pathname !== "/signin" && window.location.pathname !== "/signup") {
            window.location.reload();
          }
        }
        localStorage.setItem("accessToken", token);
      });
    }
  });

  useEffect(() => {
    updateAuthToken();
    setTimeout(updateAuthTokenWithoutRefresh, 1 * 10 * 1000);
  }, []);

  return (
    <AuthContext.Provider value={authContext}>
      <Provider store={RootStore}>
        <Router>
          <Routes>
            {/* Basic Pages */}
            {/* <Route path="/" element={<Home />} /> */}
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/signin" element={<Signin />} />
            <Route path="/lawyer" element={<RequireAuth><LawyerPage /></RequireAuth>} />
            <Route path="/l/:chatId" element={<RequireAuth><LawyerPage /></RequireAuth>} />
            <Route path="/c/:chatId" element={<RequireAuth><ChatPage /></RequireAuth>} />
            <Route path="/" element={<RequireAuth><ChatPage /></RequireAuth>} />
            <Route path="/onboarding" element={<RequireAuth><OnboardingPage /></RequireAuth>} />
            <Route path="/update-profile" element={<RequireAuth><OnboardingPage /></RequireAuth>} />
          </Routes>
        </Router>
      </Provider>
    </AuthContext.Provider>

  );
}

export default App;
