
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../firebase";
import axios from "axios";

const BASE_ENDPOINT = "https://lexxa-backend-pfsegbje6a-uw.a.run.app";

const initialState = {
  isAuthenticated: false,
  user: null,
  userType: null,
  error: null,
  refreshToken: null,
  accessToken: null,
  infoMissing: false,
  status: "idle",
  firebase_user: null,
};

const triggerLogout = () => {
  try {
    signOut(auth)
  }
  catch (error) { }
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("userType");
  localStorage.removeItem("client_details");
  localStorage.removeItem("lawyer_info");
  window.location = "/signin";

}
export const attemptAuthenticationRefresh = () => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      try {
        user.getIdToken().then((token) => {
          localStorage.setItem("accessToken", token);
          window.location.reload();
        }).catch(firebaseError => {
          triggerLogout()
        })
      }
      catch (UserError) {
        triggerLogout()
      }
    }
    else {
      triggerLogout()
    }
  })
}

export const loadDataFromLocalStorage = createAsyncThunk("authentication/loadDataFromLocalStorage", async (thunkAPI) => {
  return thunkAPI.rejectWithValue();
})

export const getUserDetails = createAsyncThunk("authentication/getUserDetails", async (thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const url = `${BASE_ENDPOINT}/api/v0/user/get-info`;
  const headers = { "Authorization": `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url, { "headers": headers });
    return {
      "response": response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
})

export const signUp = createAsyncThunk("authentication/signUp", async (data, thunkAPI) => {
  try {
    const response = await createUserWithEmailAndPassword(auth, data.email, data.password);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
})

export const signIn = createAsyncThunk("authentication/signUp", async (data, thunkAPI) => {
  try {
    const response = await signInWithEmailAndPassword(auth, data.email, data.password);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
})

export const handleLogout = createAsyncThunk("authentication/handleLogout", async (thunkAPI) => {
  try {
    const response = await signOut(auth)
    return response;
  }
  catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
})

export const refreshAuth = createAsyncThunk("authentication/signUp", async (thunkAPI) => {
  // implement refresh token
})



const AuthenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.status = "idle";
      state.isAuthenticated = true;
      state.user = action.payload;
      state.accessToken = action.payload.user.accessToken;
      state.refreshToken = action.payload.user.stsTokenManager.refreshToken;
      state.error = null;
    }).addCase(signUp.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.status = "failed";
      state.user = null;
      if (action.payload?.error?.code === "auth/invalid-credential") {
        state.error = { message: "Invalid email or password" };
      }
      else {
        state.error = { message: "Something went wrong. Please try again later." };
      }
      // state.error = action.payload;
      state.accessToken = null;
      state.refreshToken = null;
    }).addCase(signUp.pending, (state, action) => {
      state.status = "loading";
      state.isAuthenticated = false;
      state.user = null;
      state.error = null;
    })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        var user_type = action.payload.response.user_type;
        var client_details = action.payload.response.client_info;
        var lawyer_info = action.payload.response.lawyer_info;


        var user_details = {};
        if (user_type === "lawyer") {
          user_details = action.payload.response.lawyer_info;
          if (user_details == null) {
            user_details = {
              "first_name": "",
              "last_name": "",
              "user_id": action.payload.response.user_id
            }
          }
        }
        else {
          user_details = action.payload.response.client_info;
          if (user_details == null) {
            user_details = {
              "first_name": "",
              "last_name": "",
              "organization_name": "",
              "phone_number": "",
              "state": "",
              "age_of_business": "",
              "industry": "",
              "num_employees": "",
              "annual_revenue": "",
              "user_id": action.payload.response.user_id
            }
          }
        }
        user_details["user_id"] = action.payload.response.user_id;
        state.user = user_details;
        localStorage.setItem("userType", action.payload.response.user_type);
        localStorage.setItem("user", JSON.stringify(user_details));
        localStorage.setItem("client_details", JSON.stringify(client_details));
        localStorage.setItem("lawyer_info", JSON.stringify(lawyer_info));
        state.client_details = client_details;
        state.lawyerInfo = lawyer_info;
        state.clientDetails = client_details;
        state.userType = user_type;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.firebase_user = null;
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.firebase_user = null;
      })
      .addCase(loadDataFromLocalStorage.rejected, (state, action) => {
        state.userType = localStorage.getItem("userType");
        state.user = JSON.parse(localStorage.getItem("user"));
        try {
          state.lawyerInfo = JSON.parse(localStorage.getItem("lawyer_info"));
        } catch (e) {
          state.lawyerInfo = null;
        }
        try {
          state.clientDetails = JSON.parse(localStorage.getItem("client_details"));
        } catch (e) {
          state.clientDetails = null;
        }
        if (state.lawyerInfo === undefined || state.lawyerInfo === null || state.clientDetails === undefined || state.clientDetails === null) {
          state.infoMissing = true;
        }
        else {
          state.infoMissing = false;
        }
        state.firebase_user = null;
      })

      .addCase(handleLogout.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = null;
        state.accessToken = null;
        state.refreshToken = null;
        state.status = "idle";
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        localStorage.removeItem("userType");
        localStorage.removeItem("client_details");
        localStorage.removeItem("lawyer_info");
        window.location = "/signin";
      })
  }
});

export const { loginSuccess, loginFailure, logout } = AuthenticationSlice.actions;

export default AuthenticationSlice.reducer;
