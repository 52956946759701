import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { useEffect } from "react";
// import { auth } from "./firebase";

const firebaseConfig = {
    apiKey: "AIzaSyC2REt5iRyPz2z8seiike5sTzrwxpsUTv0",
    authDomain: "lexxa-platform.firebaseapp.com",
    projectId: "lexxa-platform",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }
