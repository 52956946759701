import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import chatSliceReducer from "../../pages/chatpage/chatSlice";
import onboardingSlice from "../../pages/onboarding/onboardingSlice";
import AuthenticationSlice from "../../pages/UserAuthentication/AuthenticationSlice";

const allReducers = combineReducers({
  chat: chatSliceReducer,
  onboarding: onboardingSlice,
  auth: AuthenticationSlice,
})

const applicationStore = createStore(
    allReducers,
    applyMiddleware(thunkMiddleware)
  );
export default applicationStore;
  