import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { handleLogout } from "../UserAuthentication/AuthenticationSlice";
import { marked } from "marked";

import {
    sendMessage,
    enableNewChat,
    disableNewChat,
    setThreadId,
    getThread,
    getAllClients,
    getAllThreads,
    updateSubmittedChats,
    // resetChatPage,
    // deleteThread,
    submitLegalReview
} from "./chatSlice";
import { useNavigate } from "react-router-dom";
import { loadDataFromLocalStorage } from "../UserAuthentication/AuthenticationSlice";

function LawyerPage() {
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [showClientDetails, setShowClientDetails] = useState(false);
    // const [action, setAction] = useState("");
    // const [messageId, setMessageId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    // const [copiedItem, setCopiedItem] = useState("");
    // const [customerId, setCustomerId] = useState("");
    const [userImageMapping, setUserImageMapping] = useState({});
    const [selectedClient, setSelectedClient] = useState("");
    const [submitReviewDisabled, setSubmitReviewDisabled] = useState(false);
    const [involvedUsers, setInvolvedUsers] = useState([]);
    const [userImage, setUserImage] = useState();
    const [reviewNotYetRequested, setReviewNotYetRequested] = useState(false);
    const [basicModal2, setBasicModal2] = useState(false);
    const [reviewNotYetAdded, setReviewNotYetAdded] = useState(true);

    const dispatch = useDispatch();
    const messages = useSelector((state) => state.chat.messages);
    const status = useSelector((state) => state.chat.status);
    const threads = useSelector((state) => state.chat.threads);
    const clients = useSelector((state) => state.chat.clients);
    // const threadId = useSelector((state) => state.chat.threadId);
    const getAllThreadError = useSelector((state) => state.chat.getAllThreadError);
    const getAllThreadStatus = useSelector((state) => state.chat.getAllThreadStatus);
    const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
    const getThreadError = useSelector((state) => state.chat.getThreadError);
    // const fetchedThreadId = useSelector((state) => state.chat.fetchedThreadId);
    const user = useSelector((state) => state.auth.user);
    // const userType = useSelector((state) => state.auth.userType);
    const legalReviewSubmitted = useSelector((state) => state.chat.legalReviewSubmitted);
    const submitLegalReviewStatus = useSelector((state) => state.chat.submitLegalReviewStatus);
    const getAllClientsStatus = useSelector((state) => state.chat.getAllClientsStatus);
    const submittedChats = useSelector((state) => state.chat.submittedChats);
    const submittedReviews = useSelector((state) => state.chat.submittedReviews);
    const groupedthreads = useSelector((state) => state.chat.threadsNewStructure);


    const toggleOpen2 = () => setBasicModal2(!basicModal2);

    let { chatId } = useParams();

    const location = useLocation();
    useEffect(() => {
        dispatch(getAllClients({}));
        dispatch(loadDataFromLocalStorage());
    }, []);

    useEffect(() => {
        if (selectedClient !== "" || submitLegalReviewStatus == "success") {
            dispatch(getAllThreads({ userId: selectedClient }));
        }
    }, [selectedClient, submitLegalReviewStatus]);

    const convertToHtml = (text) => {
        return marked(text.replace(/\n/g, '<br>'));
    }

    useEffect(() => {
        // legalReviewSubmitted(false);
        setReviewNotYetAdded(true)
        if (chatId !== null) {
            var loggedInUserMessages = []
            for (var i = 0; i < messages.length; i++) {
                if (messages[i].user != null && messages[i].user.user_id === user.user_id) {
                    loggedInUserMessages.push(messages[i]);
                }
            }
            // const loggedInUserMessages = messages.filter((message) => message.user.user_id === user.user_id);
            if (loggedInUserMessages.length > 0) {
                setReviewNotYetAdded(false)
            }
        }
    }, [messages, chatId]);

    // setSubmitReviewDisabled or not 
    useEffect(() => {
        setSubmitReviewDisabled(false)
        setReviewNotYetRequested(false)
        if (legalReviewSubmitted) {
            setSubmitReviewDisabled(true);
        }
        else {
            var currentThread = threads.find((thread) => thread.thread_id === chatId);
            if (currentThread !== undefined && !currentThread.legal_review_requested) {
                setSubmitReviewDisabled(true);
                setReviewNotYetRequested(true)
            }
            else if (currentThread !== undefined && currentThread.legal_review_submitted) {
                setSubmitReviewDisabled(true);
                setReviewNotYetRequested(false)
            }
            else {
                if (submitLegalReviewStatus === "loading") {
                    setSubmitReviewDisabled(true);
                }
                setSubmitReviewDisabled(false);
            }
        }
    }, [legalReviewSubmitted, threads, chatId]);

    useEffect(() => {
        if (status === "idle") {
            setMessage("");
        }
        if (status === "failed") {
            setErrorMessage("Failed to send message");
        }
    }, [status]);

    useEffect(() => {
        if (location.pathname !== "/lawyer") {
            dispatch(getThread(chatId));
            dispatch(updateSubmittedChats(chatId));
        }
    }, [location.pathname]);

    if (chatId === undefined) {
        chatId = null;
        dispatch(enableNewChat());
    } else {
        dispatch(disableNewChat());
        dispatch(setThreadId(chatId));
    }

    const submitReview = () => {
        dispatch(submitLegalReview({ thread_id: chatId }));
    }

    const handleSend = () => {
        dispatch(sendMessage({ message_text: message, thread_id: chatId }));
    };
    const signout = () => {
        dispatch(handleLogout())
    }
    const refetch = () => {
        dispatch(getAllThreads({ userId: selectedClient }));
    }

    useEffect(() => {
        if (messages.length > 0) {
            var userImageMapping = {}
            var users = []
            messages.forEach(function (message) {
                if (message.user != null) {
                    if (message.user.user_type == "lawyer") {
                        userImageMapping[message.user.user_id] = require(`../../images/lawyer.png`);
                    }
                    else {
                        userImageMapping[message.user.user_id] = require(`../../images/white/${message.user.first_name[0].toUpperCase()}.png`);
                    }
                    if (users.indexOf(message.user.user_id) === -1) {
                        users.push(message.user.user_id);
                    }
                }
            });
            userImageMapping['lexxa'] = require(`../../images/gradient/LEXXA.png`);
            setUserImageMapping(userImageMapping);
            setInvolvedUsers(users);
        }
        try {
            var mydiv = document.getElementsByClassName("chat-content-area")[0]
            mydiv.scrollTo({ top: mydiv.scrollHeight, behavior: "smooth" });
        }
        catch (error) {

        }
    }, [messages]);

    useEffect(() => {
        if (involvedUsers.length > 0 && Object.keys(clients).length > 0) {
            involvedUsers.forEach(function (user_id) {
                if (clients[user_id] != undefined) {
                    setSelectedClient(user_id)
                }
            })
        }
    }, [involvedUsers, clients]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setUserImage(require(`../../images/lawyer.png`));

    }, []);
    return (
        <div className="App">
            <MDBModal open={basicModal2} setopen={setBasicModal2} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle className="text-dark">Profile details</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleOpen2}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <div class="row">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">First Name</th>
                                            <td>{user?.first_name}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>{user?.last_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </MDBModalBody>

                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleOpen2}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <section className="bannerContainer">
                <div class="container-fluid">
                    <div class="row">
                        <div class="side-nav col-lg-3 col-md-12">
                            <div class="side-nav-threads">
                                <div class="row p-3 pb-0">
                                    <select
                                        class="col-12 form-select form-select-lg mt-3 bg-dark text-white"
                                        name="client"
                                        value={selectedClient}
                                        onChange={(e) => setSelectedClient(e.target.value)}
                                    >
                                        <option value='' selected>Select Client</option>
                                        {Object.entries(clients).map(([user_id, client]) => (
                                            <option value={user_id}>{client.first_name} {client.last_name} | {client.organization_name}</option>
                                        ))}
                                    </select>
                                    {selectedClient !== "" ? (
                                        <>
                                            <p class="text-white">{!showClientDetails ?
                                                <span className="cursor-pointer" onClick={() => { setShowClientDetails(!showClientDetails) }}>Know more about client <i class="bi bi-info-circle"></i></span> :
                                                <span className="cursor-pointer" onClick={() => { setShowClientDetails(!showClientDetails) }}>Hide Client Details</span>
                                            }
                                            </p>
                                            {showClientDetails ? (
                                                <p>
                                                    <b>Name:</b> {clients[selectedClient].first_name} {clients[selectedClient].last_name} <br />
                                                    <b>Organization:</b> {clients[selectedClient].organization_name} <br />
                                                    <b>State:</b> {clients[selectedClient].state} <br />
                                                    <b>Size:</b> {clients[selectedClient].num_employees} <br />
                                                    <b>Revenue:</b> {clients[selectedClient].annual_revenue} <br />
                                                </p>
                                            ) : null}
                                        </>
                                    ) : null}
                                </div>
                                <div class="row">
                                    {getAllClientsStatus === "loading" ?
                                        <div class="col-10 offset-1">
                                            <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                                            </div>
                                            Fetching Clients...
                                        </div>
                                        : ""}
                                    <ul class="p-3 pb-0">
                                        {(getAllThreadStatus === "failed" && Object.keys(getAllThreadError.fieldErrors).length === 0) ? (
                                            <>
                                                <p class="text-danger">Failed to load history. Please try again later.</p>
                                                <button class="btn btn-primary btn-sm" onClick={refetch}>Retry</button>
                                            </>
                                        ) : null}
                                        {getAllThreadStatus === "loading" ? (
                                            <div class="chat-content-area">
                                                <div class="row">
                                                    <div class="col-md-2 offset-md-4 mt-5">
                                                        <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>) : null}

                                        {Object.entries(groupedthreads).map(([key, threadGroup]) => (
                                            <>
                                                <div class="row p-3 pb-0">
                                                    <p key={key} className="text-secondary px-2 py-0 my-0">{key}</p>
                                                </div>
                                                <ul class="my-0">

                                                    {threadGroup.map((thread, index) => (
                                                        <li key={index} className={thread.thread_id == chatId ? "row thread bg-secondary text-white" : "row thread"}>
                                                            <div class="chat-list-item col-12" onClick={() => {
                                                                navigate(`/l/${thread.thread_id}`);
                                                            }}>
                                                                <p class="">
                                                                    {thread.legal_review_requested && !thread.legal_review_submitted && submittedChats.indexOf(thread.thread_id === -1) ? (
                                                                        <i class="bi bi-circle-fill me-2 text-danger"></i>
                                                                    ) : null}
                                                                    {thread.legal_review_requested && thread.legal_review_submitted ? (
                                                                        <i class="bi bi-check-lg me-2 text-success"></i>
                                                                    ) : null}
                                                                    {thread.title}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ))}

                                        {/* {threads.map((thread, index) => (
                                            <li key={index} className={thread.thread_id === chatId ? "row bg-secondary text-white" : "row"}>
                                                <div class="chat-list-item col-12" onClick={() => {
                                                    navigate(`/l/${thread.thread_id}`);
                                                }}>
                                                    <p>
                                                        {thread.legal_review_requested && !thread.legal_review_submitted && submittedChats.indexOf(thread.thread_id != -1) ? (
                                                            <i class="bi bi-circle-fill ms-3 text-danger"></i>
                                                        ) : null} {thread.title}</p>
                                                </div>
                                            </li>
                                        ))} */}
                                    </ul>
                                </div>
                            </div>
                            <div class="row side-nav-footer py-2">
                                <div class="col-12 px-4">
                                    <MDBDropdown>
                                        <MDBDropdownToggle tag='p'>
                                            <img src={userImage} class="chatgpt-icon ms-3 me-2" /> {user?.first_name}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem link onClick={toggleOpen2}>View Profile</MDBDropdownItem>
                                            <MDBDropdownItem link onClick={signout}>Logout</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </div>
                            </div>

                        </div>

                        <div class="content  p-0 pt-2 col-lg-9 col-md-12">
                            {chatId !== null ?
                                <>
                                    {getThreadStatus !== "loading" ?
                                        <>
                                            <div class="chat-content-area">

                                                {messages.map((message, index) => (
                                                    <div key={index} className={index % 2 === 0 ? "row gpt-chat-box" : "row user-chat-box"}>
                                                        <div class="chat-icon col-1 text-center">
                                                            {message.user == null ? (
                                                                <img class="chatgpt-icon" src={userImageMapping['lexxa']} />)
                                                                : <img class="chatgpt-icon" src={userImageMapping[message.user.user_id]} />
                                                            }
                                                        </div>
                                                        <div class="chat-txt col-10">
                                                            <p class="text-larger">
                                                                <b>{message.user === null ? "Lexxa" : <>
                                                                    {message.user.user_id === user.user_id ? <>You</> : <>{message.user.first_name}</>}
                                                                    {message.user.user_type === "lawyer" ? <> (Legal Advisor)</> : null}
                                                                </>}
                                                                </b>
                                                            </p>
                                                            <div dangerouslySetInnerHTML={{ __html: convertToHtml(message.text) }}></div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div class="chat-input-area overflow-hidden">
                                                <div class="row">
                                                    <div class="col-12 col-md-8 text-end">
                                                        <span style={{ visibility: status !== "loading" ? "hidden" : "visible" }}>
                                                            <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                            {"   "} Please wait up to 30 seconds while we analyze your request.
                                                        </span>
                                                    </div>
                                                    <div class="col-md-4 col-12">
                                                        {!submitReviewDisabled ?
                                                            <button class="btn btn-outline-dark btn-sm float-start" onClick={() => {
                                                                if (window.confirm("Are you sure you want to submit the review?")) {
                                                                    submitReview();
                                                                }
                                                            }}
                                                                disabled={submitReviewDisabled || reviewNotYetAdded}>Submit Legal Review</button> : null}
                                                    </div>
                                                    <div class="col-12 chat-inputs-area-inner pt-3">
                                                        {!submitReviewDisabled ? (
                                                            <div class="row chat-inputs-container align-items-center">
                                                                <textarea
                                                                    name=""
                                                                    id=""
                                                                    class="col-11 pb-0"
                                                                    placeholder="Add Your Review Here"
                                                                    value={message}
                                                                    onChange={(e) => {
                                                                        setMessage(e.target.value);
                                                                        setErrorMessage("");
                                                                    }}
                                                                    disabled={status === "loading"}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "Enter" && !e.shiftKey && (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)) {
                                                                            handleSend();
                                                                        }
                                                                    }}
                                                                ></textarea>

                                                                <span class="col-1">
                                                                    <button class="btn btn-outline-dark btn-sm" onClick={handleSend} disabled={status === "loading"}>
                                                                        <i class="bi bi-arrow-up cursor" style={{ fontSize: "20px" }} aria-hidden="true" ></i>
                                                                    </button>
                                                                </span>


                                                            </div>)
                                                            : <>
                                                                {(reviewNotYetRequested) ? <p class="text-muted text-center">The user has not yet requested review upon the thread</p> : null}
                                                                {(submitReviewDisabled && !reviewNotYetRequested) ? <p class="text-muted text-center">You have already submitted the review</p> : null}
                                                            </>

                                                        }
                                                        {(getThreadStatus === "failed" && Object.keys(getThreadError.fieldErrors).length === 0 && message.length === 0) ? (
                                                            <>
                                                                {getThreadError.genericErrors.length > 0 ? (
                                                                    <>
                                                                        {getThreadError.genericErrors.map((error) => (
                                                                            <p class="text-danger">Reason: {error}</p>
                                                                        ))}
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                        {errorMessage === "" ? null : <p class="text-danger">{errorMessage}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </> : <div class="chat-content-area">
                                            <div class="row">
                                                <div class="col-md-2 offset-md-5 mt-5">
                                                    <div class="spinner-grow spinner-grow-sm text-primary me-3" role="status">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </> : <div class="row">
                                    <p class="text-center mt-5">
                                        {selectedClient === "" ? "Please select a client to start the conversation" : "Please select a thread to start the conversation"}
                                    </p>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default LawyerPage;
