import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { randomId } from "./helpers";
import { extractErrors } from "../helpers";
import axios from "axios";

import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";
// import { useDispatch } from "react-redux";

// const dispatch = useDispatch();

const BASE_ENDPOINT = "https://lexxa-backend-pfsegbje6a-uw.a.run.app";

// done
export const sendMessage = createAsyncThunk("chat/sendMessage", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  var url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/add-message`;
  if (!data.thread_id) {
    url = `${BASE_ENDPOINT}/api/v0/thread/create`;
  }
  const headers = { "Authorization": `Bearer ${accessToken}` };
  try {
    const response = await axios.post(url, data, { "headers": headers });
    return {
      "response": response.data,
      "request_data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const getAllClients = createAsyncThunk("chat/getAllClients", async (data, thunkAPI) => {
  // method to call the api to get all the clients of the lawyer. Can be called only from the lawyer interface
  const accessToken = localStorage.getItem("accessToken");
  const url = `${BASE_ENDPOINT}/api/v0/lawyer/list-clients`;
  const headers = { "Authorization": `Bearer ${accessToken}` };
  try {
    const response = await axios.get(url, { "headers": headers });
    return {
      "response": response.data,
      "request_data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const requestLegalReview = createAsyncThunk("chat/requestLegalReview", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };
  const url = `${BASE_ENDPOINT}/api/v0/thread/${data.threadId}/request-legal-review`;
  try {
    const response = await axios.post(url, data, { "headers": headers });
    return {
      "response": response.data,
      "request_data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});



export const submitLegalReview = createAsyncThunk("chat/submitLegalReview", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };
  const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/submit-legal-review`;
  try {
    const response = await axios.post(url, {}, { "headers": headers });
    return {
      "response": response.data,
      "request_data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const sendFeedback = createAsyncThunk("chat/sendFeedback", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/message/${data.message_id}/add-user-feedback`;
    const response = await axios.post(url, data, { "headers": headers });
    return {
      "response": response.data,
      "data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});
export const sendFeedbackWithMessage = createAsyncThunk("chat/sendFeedbackWithMessage", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };
  try {
    const url = `${BASE_ENDPOINT}/api/v0/message/${data.message_id}/add-user-feedback`;
    const response = await axios.post(url, data, { "headers": headers });
    return {
      "response": response.data,
      "data": data
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


// done
export const getThread = createAsyncThunk("chat/getThread", async (chatId, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${chatId}/list-messages`;
    const response = await axios.get(url, { "headers": headers });
    return { "data": response.data, "threadId": chatId };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error, "threadId": chatId });
  }
});

// done
export const deleteThread = createAsyncThunk("chat/deleteThread", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.threadId}/delete`;
    const response = await axios.delete(url, { "headers": headers });
    return { "response": response.data, "request": data };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


export const getTitle = createAsyncThunk("chat/getTitle", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data[0]}/get-title`;
    const response = await axios.get(url, { "headers": headers });
    return {
      "response": response.data,
      "index": data[1]
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

export const updateTitle = createAsyncThunk("chat/updateTitle", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/thread/${data.thread_id}/update-title`;
    const response = await axios.post(url, data, { "headers": headers });
    return {
      "response": response.data,
      "index": data[1],
      // "title": data[0]
    };
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

// done
export const getDynamicQuestions = createAsyncThunk("chat/getDynamicQuestions", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/get-dynamic-examples`;
    const response = await axios.get(url, { "headers": headers });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

// done
export const getAllThreads = createAsyncThunk("chat/getAllThreads", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const url = `${BASE_ENDPOINT}/api/v0/user/${data.userId}/list-threads`;
    const response = await axios.get(url, { "headers": headers });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});


const chatSlice = createSlice({
  name: "chat",
  initialState: {
    messages: [],
    viewedChats: [],
    submittedChats: [],
    status: "idle",
    error: null,
    newChat: "new",
    threadId: null,
    nextStep: null,
    threads: [],
    threadsNewStructure: {},
    feedbackStatus: "idle",
    getThreadStatus: "idle",
    deleteThreadStatus: "idle",
    getTitleStatus: "idle",
    updateTitleStatus: "idle",
    updateTitleError: null,
    getDynamicQuestionsStatus: "idle",
    getAllThreadStatus: "idle",
    feedbackError: null,
    getThreadError: {},
    getThreadsError: null,
    deleteThreadError: null,
    getTitleError: null,
    getDynamicQuestionsError: null,
    getAllThreadError: null,
    dynamicQuestions: [],
    fetchedThreadId: "",
    getAllClientsStatus: "idle",
    getAllClientsError: null,
    clients: [],
    messagingDisabled: false,
    requestLegalReviewStatus: "idle",
    requestLegalReviewError: null,
    notUpdatedThreads: [],
    submitLegalReviewStatus: "idle",
    submitLegalReviewError: null,
    legalReviewSubmitted: false,
    legalReviewRequested: false
  },
  reducers: {
    addFeedbackToMessage: (state, data) => {
      const messages = JSON.parse(JSON.stringify(state.messages));
      var messageIndex = 0;
      for (var i = 0; i < messages.length; i++) {
        if (messages[i].id === data.payload.message_id) {
          messageIndex = i;
          break;
        }
      }
      if (messageIndex > -1) {
        messages[messageIndex].feedback_type = data.payload.action;
      }
      state.messages = [...messages];
    },
    updateViewedChats: (state, chatId) => {
      state.viewedChats = [...state.viewedChats, chatId.payload];
      console.log(state.viewedChats, chatId)
    },
    updateSubmittedChats: (state, chatId) => {
      state.submittedChats = [...state.submittedChats, chatId.payload];
    },
    enableNewChat: (state) => {
      state.newChat = "new";
    },
    disableNewChat: (state) => {
      state.newChat = "old";
    },
    setThreadId: (state, action) => {
      state.threadId = action.payload;
    },
    resetChatPage: (state, action) => {
      state.messages = [];
      state.status = "idle";
      state.error = null;
      state.newChat = "new";
      state.threadId = null;
      state.nextStep = null;
      state.feedbackStatus = "idle";
      state.getThreadStatus = "idle";
      state.deleteThreadStatus = "idle";
      state.getTitleStatus = "idle";
      state.getDynamicQuestionsStatus = "idle";
      state.getAllThreadStatus = "idle";
      state.feedbackError = null;
      state.legalReviewSubmitted = false;
      state.messagingDisabled = false;
      state.getThreadError = null;
      state.getThreadsError = null;
      state.deleteThreadError = null;
      state.getTitleError = null;
      state.getDynamicQuestionsError = null;
      state.getAllThreadError = null;
      state.legalReviewRequested = false;
    },
    updateGetAllClientStatus: (state) => {
      state.getAllClientsStatus = "loading"
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = "";
        state.threadId = action.payload.request_data.thread_id;
        var userType = localStorage.getItem("userType");
        var user = JSON.parse(localStorage.getItem("user"));
        if (userType === "lawyer") {
          state.messages = [
            ...state.messages,
            {
              text: action.payload.request_data.message_text,
              creation_time: action.payload.response.creation_time,
              message_id: action.payload.response.message_id,
              id: action.payload.response.message_id,
              user: user
            }
          ]
        }
        else {
          state.messages = [
            ...state.messages,
            {
              user: user,
              text: action.payload.request_data.message_text,
              creation_time: action.payload.response.creation_time,
              message_id: action.payload.response.message_id,
              id: action.payload.response.message_id,
            },
            {
              user: null,
              text: action.payload.response.ai_reply.text,
              creation_time: action.payload.response.creation_time,
              message_id: action.payload.response.ai_reply.message_id,
              id: action.payload.response.ai_reply.message_id,
            },
          ];
        }
        state.nextStep = action.payload.next_step;
        if (!state.threadId) {
          state.threadId = action.payload.response.thread_id;
          state.newChat = "old";
          window.history.pushState("", "", `/c/${state.threadId}`);
        }
      })
      .addCase(sendMessage.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }
        state.status = "failed";
        state.error = action.payload.error;
      })

      // sendFeedback handlers
      .addCase(sendFeedbackWithMessage.pending, (state) => {
        state.feedbackStatus = "loading";
        state.error = "";
      })
      .addCase(sendFeedbackWithMessage.fulfilled, (state, action) => {
        if (action.payload.data.feedback !== "") {
          state.feedbackStatus = "success";
        }
        else {
          state.feedbackStatus = "idle";
        }
        state.error = "";
        state.threadId = action.payload.thread_id;
        state.nextStep = action.payload.next_step;
      })
      .addCase(sendFeedbackWithMessage.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }
        state.feedbackStatus = "failed";
        state.error = action.payload.error;
      })

      // getThread handlers
      .addCase(getThread.pending, (state) => {
        state.getThreadStatus = "loading";
        state.getThreadError = "";
        state.messages = [];
      })
      .addCase(getThread.fulfilled, (state, action) => {
        state.getThreadStatus = "idle";
        state.getThreadError = extractErrors(action.payload?.error?.response);
        state.fetchedThreadId = action.payload.threadId;
        state.messages = action.payload.data.messages.sort((a, b) => a.sequence_number - b.sequence_number);
        state.legalReviewRequested = false;
        state.legalReviewSubmitted = false;
        state.messagingDisabled = action.payload.data.messaging_disabled;
      })
      .addCase(getThread.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }

        state.getThreadStatus = "failed";
        state.getThreadError = extractErrors(action.payload.error.response);
        if (Object.keys(state.getThreadError.fieldErrors).length === 0 && state.getThreadError.genericErrors.length === 0) {
          state.getThreadError["genericErrors"] = ["Something went wrong"];
        }
        console.log(state.getThreadError)
        state.fetchedThreadId = action.payload.threadId;
      })

      // deleteThread handlers
      .addCase(deleteThread.pending, (state) => {
        state.deleteThreadStatus = "loading";
        state.deleteThreadError = "";
      })
      .addCase(deleteThread.fulfilled, (state, action) => {
        state.deleteThreadStatus = "success";
        state.deleteThreadError = "";

        const threads = [...state.threads];
        const threadIndex = threads.findIndex(thread => thread.thread_id === action.payload.request.threadId);
        if (threadIndex !== -1) {
          threads.splice(threadIndex, 1);
        }
        state.threads = [...threads];
        if (state.threadId === action.payload.request.threadId) {
          state.threadId = null;
          window.history.pushState("", "", "/");
          state.messages = [];
        }
      })
      .addCase(deleteThread.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }

        state.deleteThreadStatus = "failed";
        state.deleteThreadError = extractErrors(action.payload.error);
      })

      // getTitle handlers
      .addCase(getTitle.pending, (state) => {
        state.getTitleStatus = "loading";
        state.getTitleError = "";
      })
      .addCase(getTitle.fulfilled, (state, action) => {
        state.getTitleStatus = "idle";
        state.getTitleError = "";
        state.threads[action.payload.index].title = action.payload.response.title;
      })
      .addCase(getTitle.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }
        state.getTitleStatus = "failed";
        state.getTitleError = action.payload.error;
      })


      // updateTitle handlers
      .addCase(updateTitle.pending, (state) => {
        state.updateTitleStatus = "loading";
        state.updateTitleError = "";
      })
      .addCase(updateTitle.fulfilled, (state, action) => {
        state.updateTitleStatus = "success";
        state.updateTitleError = "";
        // state.threads[action.payload.index].title = action.payload.title;
      })
      .addCase(updateTitle.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }

        state.updateTitleStatus = "failed";
        state.updateTitleError = action.payload.error;
      })

      // getDynamicQuestions handlers
      .addCase(getDynamicQuestions.pending, (state) => {
        state.getDynamicQuestionsStatus = "loading";
        state.getDynamicQuestionsError = "";
      })
      .addCase(getDynamicQuestions.fulfilled, (state, action) => {
        state.getDynamicQuestionsStatus = "idle";
        state.getDynamicQuestionsError = "";
        state.dynamicQuestions = action.payload.questions;
      })
      .addCase(getDynamicQuestions.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }

        state.getDynamicQuestionsStatus = "failed";
        state.getDynamicQuestionsError = action.payload.error;
      })

      // getAllThreads handlers
      .addCase(getAllThreads.pending, (state) => {
        state.getAllThreadStatus = "loading";
        state.getAllThreadError = "";
      })
      .addCase(getAllThreads.fulfilled, (state, action) => {
        state.getAllThreadStatus = "idle";
        state.getAllThreadError = { "genericErrors": [], "fieldErrors": {} };
        state.threads = action.payload.threads.sort((a, b) => new Date(a.creation_time) - new Date(b.creation_time)).reverse();
        const groupedThreads = state.threads.reduce((acc, thread) => {
          const date = new Date(thread.creation_time);
          const month = date.toLocaleString('default', { month: 'long' });
          const year = date.getFullYear();
          const key = `${month} ${year}`;

          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(thread);

          return acc;
        }, {});
        state.threadsNewStructure = groupedThreads;


        var notUpdatedThreads = [];
        for (var i = 0; i < state.threads.length; i++) {
          if (state.threads[i].title_source === "default") {
            notUpdatedThreads.push([state.threads[i].thread_id, i]);
            // break
          }
        }
        state.notUpdatedThreads = notUpdatedThreads;
      })
      .addCase(getAllThreads.rejected, (state, action) => {
        if (action.payload.error.response === null || action.payload.error.response === undefined) {
          state.getAllThreadStatus = "idle";
          state.getAllThreadError = { "genericErrors": [], "fieldErrors": {} };
        }
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
          state.getAllThreadStatus = "failed";
          state.getAllThreadError = { "genericErrors": [], "fieldErrors": {} };
        }

        else if (action.payload.error?.response?.status === 404) {
          state.getAllThreadStatus = "idle";
          state.getAllThreadError = { "genericErrors": [], "fieldErrors": {} };
        }
        else {
          state.getAllThreadStatus = "failed";
          state.getAllThreadError = extractErrors(action.payload.error);
        }
      })

      // getAllClients handlers
      .addCase(getAllClients.pending, (state) => {
        state.getAllClientsStatus = "loading";
        state.getAllClientsError = "";
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        state.getAllClientsStatus = "success";
        state.getAllClientsError = "";
        state.clients = action.payload.response.clients;
      })
      .addCase(getAllClients.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
          state.getAllClientsStatus = "failed";
          state.getAllClientsError = { "genericErrors": ["Please refresh"], "fieldErrors": {} };
        }
        else {
          state.getAllClientsStatus = "failed";
          state.getAllClientsError = extractErrors(action.payload.error);
        }
      })

      // requestLegalReview handlers
      .addCase(requestLegalReview.pending, (state) => {
        state.requestLegalReviewStatus = "loading";
        state.requestLegalReviewError = "";
      })
      .addCase(requestLegalReview.fulfilled, (state, action) => {
        state.requestLegalReviewStatus = "success";
        state.requestLegalReviewError = "";
        state.legalReviewRequested = true;
        state.messagingDisabled = true;
      })
      .addCase(requestLegalReview.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }
        state.requestLegalReviewStatus = "failed";
        state.requestLegalReviewError = action.payload.error;
      })

      // requestLegalReview handlers
      .addCase(submitLegalReview.pending, (state) => {
        state.submitLegalReviewStatus = "loading";
        state.submitLegalReviewError = "";
      })
      .addCase(submitLegalReview.fulfilled, (state, action) => {
        state.submitLegalReviewStatus = "success";
        state.submitLegalReviewError = "";
        state.legalReviewSubmitted = true;
      })
      .addCase(submitLegalReview.rejected, (state, action) => {
        if (action.payload.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
        }
        state.submitLegalReviewStatus = "failed";
        state.submitLegalReviewError = action.payload.error;
      });

  },
});

export default chatSlice.reducer;
export const { enableNewChat, updateGetAllClientStatus, addFeedbackToMessage, disableNewChat, setThreadId, resetChatPage, updateViewedChats, updateSubmittedChats } = chatSlice.actions;
