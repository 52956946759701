import { Navigate } from "react-router-dom";


export function RequireAuth({ children }) {
  let userToken = localStorage.getItem("accessToken");
  if (userToken == null) {
    return <Navigate to={"/signin"} />;
  }
  return userToken != null ? (
    children
  ) : (
    <Navigate to={"/signin"} />
  );
}
