import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { extractErrors } from "../helpers";
import axios from "axios";
import { attemptAuthenticationRefresh } from "../UserAuthentication/AuthenticationSlice";

const BASE_ENDPOINT = "https://lexxa-backend-pfsegbje6a-uw.a.run.app";

export const saveUserProfile = createAsyncThunk("onboarding/saveUserProfile", async (data, thunkAPI) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = { "Authorization": `Bearer ${accessToken}` };

  try {
    const response = await axios.post(`${BASE_ENDPOINT}/api/v0/user/update-client-info`, data, { headers: headers });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error });
  }
});

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: {
    status: "idle",
    error: ""
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveUserProfile.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(saveUserProfile.fulfilled, (state, action) => {
        state.status = "success";
        state.error = "";
      })
      .addCase(saveUserProfile.rejected, (state, action) => {
        state.status = "failed";
        if (action?.payload?.error?.response?.status === 401) {
          attemptAuthenticationRefresh();
          state.error = { "genericErrors": ["Unauthorized"], "fieldErrors": {} };
          return;
        }
        var errors = {
          "fieldErrors": {},
          "genericErrors": []
        }
        console.log(action.payload?.error?.response?.data?.detail)
        if (action.payload?.error?.response?.data?.detail) {
          var backendErrors = action.payload?.error?.response?.data?.detail;
          backendErrors.forEach((error) => {
            if (error.type === "missing") {
              errors["fieldErrors"][error.loc[2]] = "This field is required";
            }
            else {
              errors["fieldErrors"][error.loc[2]] = error.msg;
            }
          });
          state.error = errors;
        }
      })

  },
});

export default onboardingSlice.reducer;
