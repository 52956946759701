import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { auth } from "../../firebase";
// import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { signIn, getUserDetails } from "./AuthenticationSlice";


const Signin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [notice, setNotice] = useState("");

    const accessToken = useSelector(state => state.auth.accessToken);
    const refreshToken = useSelector(state => state.auth.refreshToken);
    const authError = useSelector(state => state.auth.error);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    // const userType = useSelector(state => state.auth.userType);
    const user = useSelector(state => state.auth.user);
    const disabled = useSelector(state => state.auth.status === "loading");

    useEffect(() => {
        if (authError) {
            setNotice(authError.message);
        }
        else {
            setNotice("");
        }
    }, [authError]);



    useEffect(() => {
        if (isAuthenticated) {
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            dispatch(getUserDetails());
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (user) {
            var userType = localStorage.getItem("userType");
            if (userType === "lawyer") {
                navigate("/lawyer");
            }
            else if (userType === "client") {
                if (user.first_name) {
                    navigate("/");
                }
                else {
                    navigate("/onboarding");
                }
            }
        }
    }, [user]);

    const loginWithUsernameAndPassword = async (e) => {
        e.preventDefault();
        dispatch(signIn({ email: email, password: password }));
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <form className="col-md-4 mt-3 pt-3 pb-3">
                    {notice !== "" &&
                        <div className="alert alert-warning" role="alert">
                            {notice}
                        </div>
                    }
                    <div className="form-control mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}></input>

                        <label htmlFor="exampleInputPassword1" className="form-label mt-5">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                        <div className="d-grid m-5">
                            <button type="submit" className="btn btn-primary pt-2 pb-2" disabled={disabled} onClick={(e) => loginWithUsernameAndPassword(e)}>Submit</button>
                        </div>
                    </div>
                    {/* <div className="mt-3 text-center">
                        <span>Need to sign up for an account? <Link to="/signup">Click here.</Link></span>
                    </div> */}
                </form>
            </div>
        </div>
    )
}

export default Signin